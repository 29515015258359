<template>
	<div class="add-classify-main">
		<div class="data-content">
			<div class="title-text">添加分类</div>
			<div class="form-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="162px" class="demo-ruleForm">
					<el-form-item label="分类名称" prop="name">
					    <el-input v-model="ruleForm.name" :placeholder="rules.name[0].message" style="width: 400px;"></el-input>
					</el-form-item>
					<el-form-item label="所属上级" prop="pid">
						<el-cascader v-model="ruleForm.pid" :options="proClass" :props="{value: 'id', label: 'name', checkStrictly: true}" placeholder="请选择所属上级"></el-cascader>
					</el-form-item>
					<el-form-item label="状态" prop="status" class="from-item">
					    <el-radio-group v-model="ruleForm.status">
							<el-radio :label="1">启用</el-radio>
							<el-radio :label="2">禁用</el-radio>
					    </el-radio-group>
					</el-form-item>
					<el-form-item> 
					    <el-button type="primary" @click="onSubmit('ruleForm')">确定</el-button>
					    <el-button @click="exitClick">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				ruleForm: {
					name: '',
					pid: [],
					status: 1
				},
				rules: {
					name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
					pid: [{ required: true, message: '请选择所属上级', trigger: 'change' }],
					status: [{ required: true, message: '请选择状态', trigger: 'change' }]
				},
				 proClass: [],
				 pageData: '',//上页传过来的数据
			}
		},
		mounted() {
			this.pageData = this.$route.query;
			this.initData();
		},
		methods:{
			initData() {
				if (this.pageData.type == 'edit' || this.pageData.type=='list_add') {
					this.$api.productCateInfo(this.pageData.id).then(res=>{
						this.ruleForm.name = res.data.name;
						this.ruleForm.status = res.data.status;
						this.ruleForm.pid = res.data.node_id;
					})					
				}
				//产品分类接口
				this.$api.cateOptionList().then(listres=>{
					this.proClass = listres.data;
				})
			},
			//确定
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							name: this.ruleForm.name,
							pid: this.ruleForm.pid[this.ruleForm.pid.length-1],
							status: this.ruleForm.status
						}
						if (this.pageData.type == 'home_add') {//首页添加
							
						} else if (this.pageData.type == 'list_add') {//列表添加下级
							data.pid = this.pageData.id;
						} else {//编辑
							Object.assign(data, {id: this.pageData.id});
						}
						this.$api.cateSave(data).then(res=>{
							this.$message({type: 'success', message: '添加成功！'})
							this.$router.go(-1);
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//处理产品分类显示数据
			getClassShowData(e, list) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].id == e.pid) {
						this.ruleForm.pid.push(list[i].id);
						this.ruleForm.pid.push(e.pid);
						this.ruleForm.pid.push(e.id);
						console.log('查看数据：', this.ruleForm);
						return;
					}
				}
			},
			//返回
			exitClick() {
				this.$router.go(-1)
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.add-classify-main{padding: 18px;width:100%; height: 100%;box-sizing: border-box; 
		.data-content{background-color: #fff;padding: 30px 0;width:100%; min-height: 100%;box-sizing: border-box;
			.title-text{font-size: 18px;color: #242D33;position: relative;margin-left: 30px;margin-bottom: 38px;
				&:before{position: absolute;content: '';left: -30px;top: 4px;height: 20px;width: 4px;background: #3984F5;}
				&::after{position: absolute;content: '';left: 82px;top: 50%;width: 100%;height: 1px;background: #EDF1F4;}
			}
		}
	}
	
</style>